<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Mis Pagos</span>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <br />
        <div>
          <v-alert v-model="alert" color="primary" elevation="2" colored-border>
            <v-row>
              <v-col cols="12" md="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-store</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ name_customer }}</v-list-item-title>
                      <v-list-item-subtitle>Razón Social</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-store-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ trade_name }}</v-list-item-title>
                      <v-list-item-subtitle>Nombre de Fantasía</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo">mdi-card-text-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ rut }}</v-list-item-title>
                      <v-list-item-subtitle>RUT</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-phone </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ phone }}</v-list-item-title>
                      <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-map-marker </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ address }}</v-list-item-title>
                      <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-email </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-progress-linear v-if="loaders" color="primary" indeterminate rounded
                        height="6"></v-progress-linear>
                      <v-list-item-title>{{ mail }}</v-list-item-title>
                      <v-list-item-subtitle>Correo electrónico</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-alert>
        </div>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <br />
        <p class="pt-5 text-subtitle-2 text-center">Pagos de servicios</p>
        <v-card>
          <v-data-table :headers="headers" :items="payments" :search="search" :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
            <template v-slot:item.name_plan="{ item }">
              <span>{{ getNamePlanByIdContract(item.id_contract) }}</span>
            </template>
            <template v-slot:item.name_service="{ item }">
              <span>{{ getServiceByIdContract(item.id_contract) }}</span>
            </template>
            <template v-slot:item.state="{ item }">
              <span v-if="item.url_file == ''">Pendiente</span>
              <span v-if="item.url_file != ''">Aceptado</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :href="'https://api.sipymex.cl/' + item.url_file" v-if="item.url_file != ''">
                    <v-icon v-bind="attrs" color="success" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver Factura</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <br />
        <v-card>
          <p class="pt-5 text-subtitle-2 text-center">Pagos de equipos</p>
          <v-data-table :headers="headersEquipment" :items="payments_equipments" :search="search" :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }" no-data-text="No hay datos disponibles">              
            <template v-slot:item.state="{ item }">
              <span v-if="item.url_file == ''">Pendiente</span>
              <span v-if="item.url_file != ''">Aceptado</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :href="'https://api.sipymex.cl/' + item.url_file" v-if="item.url_file != ''">
                    <v-icon v-bind="attrs" color="success" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver Factura</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <br />
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    loaders: true,
    search: "",
    headers: [
      { text: "Servicio", value: "name_service" },
      { text: "Plan", value: "name_plan" },
      { text: "Monto", value: "price" },
      { text: "Fecha", value: "date" },
      { text: "Estado", value: "state" },
      { text: "Acciones", value: "actions" },
    ],
    headersEquipment: [
      { text: "Empresa", value: "legal_name" },
      { text: "Equipo", value: "name_equipment" },
      { text: "Fecha de Pago", value: "date" },
      { text: "Monto total", value: "price" },
      { text: "Estado", value: "state" },
      { text: "Acciones", value: "actions" },
    ],
    name_customer: "",
    rut: "",
    address: "",
    trade_name: "",
    mail: "",
    phone: "",
    services: [],
    payments: [],
    payments_equipments: [],
    contracts: [],
    plans: [],
    billing_periods: [],
    id_customer: "",
    customers: [],
    equipments: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getPaymentsById(); //ok
    this.getPaymentsEquipmentsById(); //ok
    this.getContracts(); //ok
    this.getBillingPeriods(); //ok
    this.getPlans(); //ok
    this.getServices(); //ok
    this.getCustomerById(); //ok
    this.getCustomers(); //ok
    this.getEquipments(); //ok
  },

  watch: {},

  methods: {
    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Obtniendo clientes");
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },
    getEquipments() {
      axios
        .get("/get-equipment")
        .then((response) => {
          console.log("Obteniendo Pagos");
          console.log(response.data);
          this.equipments = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener equipos"
          );
        });
    },
    getNamePlanByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNamePlan(contract.id_plan);
      return name;
    },
    getNameEquipment(id_equipment) {
      //  this.getServices();
      let equipment = this.equipments.find(
        (equipment) => equipment.id === id_equipment
      );
      return equipment.name;
    },
    getNameCustomer(id_customer) {
      //  this.getServices();
      let customer = this.customers.find(
        (customer) => customer.id === id_customer
      );
      return customer.legal_name;
    },
    getPaymentsById() {
      let request = {
        id: this.$route.params.id,
      };
      console.log(request);
      console.log("Obtener pagos de cliente por ID");
      axios
        .post("/get-payments-by-id", request)
        .then((response) => {
          console.log("Pagos ->");
          console.log(response.data);
          this.payments = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener pagos por cliente"
          );
        });
    },
    getPaymentsEquipmentsById() {
      let request = {
        id: this.$route.params.id,
      };
      console.log(request);
      console.log("Obtener pagos de equipos de cliente por ID");
      axios
        .post("/get-payments-equipments-by-id", request)
        .then((response) => {
          console.log("Pagos ->");
          console.log(response.data);
          this.payments_equipments = response.data;
          console.log("impresion de pagos");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener pagos por cliente"
          );
        });
    },

    getContracts() {
      axios
        .get("/contracts")
        .then((response) => {
          console.log("Imprime contratos");
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener contratos"
          );
        });
    },
    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener servicios"
          );
        });
    },

    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log("Imprimiendo BillingPeriods");
          console.log(response.data);
          this.billing_periods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al otener días de facturación"
          );
        });
    },

    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log("imprime Planes");
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },

    getContractByIdCustomer() {
      let contract = this.contracts.find((c) => c.id === this.$route.params.id);
      return contract;
    },
    getContractById(id_contract) {
      let contract = this.contracts.find((c) => c.id === id_contract);
      return contract;
    },

    getDayBillingPeriodsByIdCustomer() {
      let contract = this.getContractByIdCustomer(this.$route.params.id);
      let name = this.getDayBillingPeriod(contract.id_billing_periods);

      console.log("getDayBillingPeriodsByIdContract");
      console.log("contract.id_billing_periods ");
      console.log(name);
      return name;
    },
    getDayBillingPeriod(id_billing_period) {
      console.log("BillingPeriodName");
      console.log(id_billing_period);
      let billing = this.billing_periods.find(
        (billing) => billing.id === id_billing_period
      );

      return billing.billing_period;
    },

    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);
      return plan.name;
    },

  

    getServiceByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let plan = this.plans.find((plan) => plan.id == contract.id_plan);
      let name = this.getService(plan.id_service);
      return name;
    },

    getService(id_service) {
      //  this.getServices();
      let service = this.services.find((service) => service.id === id_service);
      return service.name;
    },

    getStatePayment(id_payment) {
      //  this.getServices();
      let payment = this.payments.find((payment) => payment.id == id_payment);
      return payment.estado;
    },
    getCustomerById() {
      //  this.getServices();
      let request = {
        id: this.$route.params.id,
      };
      console.log(request);
      console.log("Obtener cliente de cliente por ID");
      axios
        .post("/customer", request)
        .then((response) => {
          console.log("Cliente ->");
          console.log(response.data);
          this.name_customer = response.data.legal_name;
          this.rut = response.data.rut;
          this.address = response.data.address;
          this.trade_name = response.data.trade_name;
          this.mail = response.data.mail;
          this.phone = response.data.phone;
          this.loaders=false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "");
        });
    },
  },
};
</script>